import axios from 'axios';
import { useAppContext } from '../utils/AppContext';
import { useEffect, useRef } from 'react';

export const api_host = process.env.REACT_APP_API_HOST;

export const useFetchSessionInfo = async () => {
  const context = useAppContext();
  // For now, we just want to run this on the first page load
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (hasRunRef.current) return;

    const fetchSessionInfo = async () => {
      const response = await fetch(api_host + '/api/session-info', {
        // Important for session-based authentication
        credentials: 'include',
      });
      const data = await response.json();

      if (data.access_token) {
        localStorage.setItem('authToken', data.access_token);
        const username = data.user.username;
        context?.setUsername(username);
      } else {
        localStorage.removeItem('authToken');
        context?.setUsername(undefined);
      }
    };

    fetchSessionInfo();
    hasRunRef.current = true;
  }, [context]);
};

const apiClient = axios.create({
  baseURL: api_host,
  headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
